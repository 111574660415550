import './Home.css'; // Assurez-vous que le chemin est correct

function Home() {
  return (
    <div>
      <header>
        <h1>Bienvenue sur lepagesolutions</h1>
        {/* Autres contenus de la page d'accueil */}
        <div id="mission">
          <p>
            LepageSolutions est votre partenaire pour optimiser vos infrastructures informatiques et améliorer vos performances opérationnelles. 
            Nous écoutons attentivement vos besoins et développons des outils personnalisés pour vous accompagner dans vos projets. 
            Faites confiance à LepageSolutions pour transformer vos défis en succès !
          </p>
        </div>
      </header>
    </div>
  );
}

export default Home;
